@import "components/components";
@import "components/variables";
@import "components/buttons";
@import "components/overrides";
@import "components/playerLabel";
@import "views/views";
html,
body {
  height: 100%;
  background-color: $primary-color;
  color: $secondary-color;
}
