.mafia-player-container {
    display:flex;
    align-items:center;
    flex-direction:column;
    position:absolute;
    text-align:center;
}

.mafia-player-label {
    color: black;
    font-weight: bolder;
    width:120px;
    position:relative;
    top:-10px;
}

.mafia-player-image{
    width:60px;
    text-align:center;  
    &-moving {
      animation: shake 2s cubic-bezier(.36,.07,.19,.97) infinite;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;}
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, -5px, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }