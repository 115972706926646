@import "./variables";

.mafia-button {
  margin: 8px;
  color: $primary-color;
  background-color: $button-color;
  border: 0;

  &-big {
    font-size: 30px;
  }

  &-centered {
    position: relative;
    margin-top: 45%;
  }

  &-flat {
    margin: 0px;
    height: 30px;
  }

  &-wide {
    width: 120px;
  }

  &-narrow {
    width: 100px;
  }

  &-disabled {
    background-color: $teriary-color !important
  }

  &-sticky {
    position: absolute;
    bottom: 5%;
  }
}

.mafia-button:disabled {
  background: grey;
}