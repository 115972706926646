.admin-container,
.help-container {
  text-align: center;

  .admin-button-container {
    width: 75%;
    margin-left: 12%;
    display: flex;
    flex-direction: column;
  }
}

.help-container {
  display: block;
  height: 86%;
  position: fixed;
  overflow: auto;
  width: 100%;
  top: 55px;

  ul {
    list-style-type: none;

    li {
      font-weight: bold;
      font-size: 22px;
      padding-top: 10px;
      text-decoration: underline;
    }
  }
}

.mafia-role-modal {
  font-size: 18px;

  h1 {
    text-align: center;
  }

  .mafia-role-header {
    text-align: center;
    font-size: 20px;
  }

  .mafia-role-subheader {
    text-align: center;
    font-size: 18px;
  }
}

.mafia-container {
  text-align: center;
}

.new-player-container {
  position: relative;
  top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.game-over-container {
  .game-over-icon {
    width: 75%;
  }
}

.lobby-users-container,
.players-container {
  overflow: auto;
  // position: fixed;
  width: 100%;
  height: 82%;
  top: 55px;

  thead {
    position: sticky;
    top: 0px;
    background-color: $secondary-color;
    color: $primary-color;
  }

  tbody {
    color: $secondary-color;

    .lobby-users-current-user {
      color: $selected-color;
    }

    .selected-scenario {
      background-color: grey;
    }
  }
}

.scenarios-container {
  height: calc(100vh - 120px);
  position: sticky;
  display: block;
  overflow: auto;
  margin-top: 10px;
}

.scenarios-container-modal {
  height: 70vh;
  position: relative;
  overflow: scroll;
  margin-top: 10px;
}

.scenarios-table {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;

  thead {
    position: relative;
    top: 0px;
    background-color: $secondary-color;
    color: $primary-color;
  }

  tbody {
    color: $secondary-color;

    .lobby-users-current-user {
      color: $selected-color;
    }

    .selected-scenario {
      background-color: grey;
    }
  }

  td {
    height: 36px;
    overflow: hidden;
  }

  tr {
    border-bottom: thin solid;
  }
}

.players-container {
  top: 75px;
}

.role-rule-row {
  text-align: left;

  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 15px;

  &-toggle {
    float: right;
  }

  &-input {
    float: right;
    text-align: center;
    width: 70px;
  }

  &-range {
    float: right;

    &-input {
      width: 70px;
    }

    &-ticks {
      margin-top: -10px;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}